import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'mpctm-tab-save-button',
  standalone: true,
  templateUrl: './tab-save-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule],
})
export class TabSaveButtonComponent {
  @Input() isDisabled = false;

  @Output() readonly saveClick: EventEmitter<void> = new EventEmitter<void>();

  onSaveClick(): void {
    this.saveClick.emit();
  }
}
