import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { IconDirective } from '@core/shared/util';

type ConditionActionButtonColor = 'success' | 'warn' | 'error' | 'accent';

@Component({
  selector: 'mpctm-condition-action-button',
  standalone: true,
  templateUrl: './condition-action-button.component.html',
  styleUrl: './condition-action-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, IconDirective],
})
export class ConditionActionButtonComponent {
  readonly label: InputSignal<string | undefined> = input<string>();

  readonly icon: InputSignal<string | undefined> = input<string>();

  readonly isDisabled: InputSignal<boolean> = input<boolean>(false);

  readonly color: InputSignal<ConditionActionButtonColor> = input<ConditionActionButtonColor>('success');

  readonly click: OutputEmitterRef<void> = output<void>();
}
